@import '@/styles/functions.scss';

.wrapper {
  --paddingTop: #{spx(10)};
  --paddingBottom: #{spx(5)};
  --paddingLeftAndRight: #{spx(6)};

  background-color: var(--dark100);

  padding: var(--paddingTop) var(--paddingLeftAndRight) var(--paddingBottom);
  position: relative;
  z-index: 11;

  display: flex;
  flex-direction: column;
  width: 100%;

  .socialsWrapper {
    width: 100%;
    display: flex;
    gap: 16px;

    .socialIcon {
      width: #{spx(4)};

      :global(.image-cont) {
        --proportion: #{spx(4)};
        height: #{spx(4)};
        width: #{spx(4)};

        img {
          object-fit: contain;
        }
      }
    }
  }

  .appLinksWrapper {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;

    .appLink {
      display: block;
      width: 24px;

      &.appLinkImage {
        padding: 0;
      }
    }
  }

  .textStores {
    padding-left: 6px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0.2) 25.4%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  @media (max-width: 695px) {
    padding: 64px 16px;

    .appLinksWrapper {
      justify-content: flex-start;
    }

    .socialsWrapper {
      justify-content: flex-start;
    }

    .shadow {
      height: 64px;
    }
  }
}

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
  padding-bottom: 80px;

  @media (max-width: 695px) {
    padding-bottom: 40px;
    flex-direction: column;
  }
}

.bottomWrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--gray100);
  padding-top: 24px;
  align-items: flex-start;
  gap: 40px;
  flex-direction: row-reverse;

  .rightCol {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 450px;
  }

  .isoWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    :global(.image-cont) {
      flex-shrink: 0;
    }

    .text {
      color: var(--white1000);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      max-width: 143px;
    }
  }

  @media (max-width: 695px) {
    flex-direction: column-reverse;
    padding-top: 40px;
    gap: 40px;

    .text {
      max-width: unset;
    }

    .rightCol {
      gap: 40px;
    }
  }
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1 0 0;
  min-width: 200px;
  max-width: 450px;

  @media (max-width: 695px) {
    gap: 40px;
    max-width: unset;
    width: 100%;
  }
}

.rightWrapper {
  display: flex;
  justify-content: space-between;
  flex: 1 0 0;
  min-width: 376px;
  max-width: 700px;
  gap: 16px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 0 0;

    .title {
      font-family: var(--urbanist);
      color: var(--white1000);
      font-size: 22px;
      font-weight: 800;
      line-height: 120%;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .text {
        font-family: var(--urbanist);
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
        position: relative;
        width: fit-content;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 1px;
          background-color: var(--white1000);
          transition: var(--trTime);
        }

        @media (hover: hover) {
          &:hover {
            &::before {
              width: 100%;
              left: 0;
            }
          }
        }

        .link {
          color: var(--white1000);
        }
      }
    }

    @media (max-width: 768px) {
      width: auto;
    }
  }

  @media (max-width: 695px) {
    max-width: unset;
    min-width: unset;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 40px;

    .column {
      min-width: min(calc(50vw - 32px), 167px);
    }
  }
}

.faq {
  background-color: #e3e3e30d;
  width: 100%;
  max-width: 446px;
  padding: #{spx(2)};
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: fit-content;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      .header .arrow {
        transform: translateX(5px);
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: #{spx(1.5)};

    .title {
      flex: 1 0 0;
      color: var(--white1000);
      font-family: var(--avenir);
      font-size: #{spx(2)};
      line-height: #{spx(3)};
      font-weight: 700;
      margin-bottom: 8px;
    }

    .arrow {
      width: 20px;
      height: 20px;
      font-size: 20px;
      transition: transform var(--trTime) ease-in-out;

      &:before {
        color: var(--coral);
      }
    }
  }

  .question {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--urbanist);
    color: var(--white1000);
    position: relative;
    padding-left: 8px;

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #fff;
      left: 0;
      top: 9px;
    }

    &.secondary {
      white-space: nowrap;
      word-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.rights {
  text-align: right;

  .text {
    color: var(--white1000);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  @media (max-width: 675px) {
    margin-top: 40px;
  }
}

.isoRight {
  flex: 1 0;
  min-width: 376px;
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 695px) {
    align-items: flex-start;
  }
}

.logo {
  width: #{spx(13)} !important;
  height: #{spx(7)} !important;
  padding: 0;

  img {
    object-fit: contain;
  }
}

.topRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 695px) {
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
  }
}
