.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .subscribeTitleWrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    .bell::before {
      color: #cd7764;
    }
  }

  .subscribeTitleWrapper p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--white1000);
  }

  .subscribeInput {
    width: 100%;
    display: flex;
    gap: 0;
    border: 1px solid var(--gray100);
    height: 44px;
    align-items: center;
    padding: 0 12px;
    color: var(--white1000);
    background-color: var(--dark100);
    border-radius: 0;

    &:focus-within {
      border-color: #fff;
      outline: 1px solid #fff;
    }
  }

  .subscribeInput.error {
    border: 2px solid var(--flame200);
  }

  .subscribeInput.succeed {
    background: #e9e4dd;
    border: none;
  }

  .succeed input {
    background: #e9e4dd;
    font-weight: 700;
    color: var(--dark100);

    &:is(
        :-webkit-autofill,
        :autofill,
        :-internal-autofill-selected,
        :-internal-autofill-previewed
      ) {
      -webkit-text-fill-color: #000;
      color: #000;
      box-shadow: none;
      transition: box-shadow 10000s, background 10000s;
    }
  }

  input {
    width: 100%;
    padding: 12px 0;
    border: none;
    background-color: transparent;
    font-family: var(--avenir);
    color: var(--white1000);
    caret-color: #fff;

    &:is(
        :-webkit-autofill,
        :autofill,
        :-internal-autofill-selected,
        :-internal-autofill-previewed
      ) {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #fff;
      color: #fff;
      -webkit-box-shadow: 0 0 0 30px var(--dark300) inset !important;
      background-color: #fff !important;
      transition: box-shadow 10000s, background 10000s;
      caret-color: #fff;
    }

    &::placeholder {
      color: rgb(117, 117, 117);
    }
  }

  .subscribeInput > span {
    height: 17px;
  }

  .subscribeInput .icon::before {
    color: #ffffff99;
  }
  .subscribeInput .icon.active::before {
    color: #cd7764;
    cursor: pointer;
  }

  .subscribeInput .checkIcon {
    padding: 2px;
    background: #e9e4dd;
    border-radius: 50%;
    border: 1px solid var(--dark100);

    &::before {
      color: var(--dark100);
    }
  }

  .errorMessage {
    font-size: 12px;
    color: #de0016;
  }

  .spinner {
    color: #cd7764;
  }
}
